// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import "channels"




Rails.start()
Turbolinks.start()



import 'bootstrap'

/* Theme JS */
//import "packs/jquery-3_5_1_min"
import "packs/plugins"
import "packs/main"
import "packs/custom"

/* Font awesome */
import '@fortawesome/fontawesome-free/css/all'

/* Application scss */
import "../stylesheets/main.scss"
